import config from '../config';

const calculatePollution = (city) => {
  if (!city.metadata || !city.metadata.properties) return 0;

  const cityBlockIds = Object.values(city.metadata.properties)
    .filter((prop) => typeof prop === 'string' && prop.includes(' - '))
    .map((prop) => parseInt(prop.split(' - ')[1]));
  const wasteCleanUpBlocks = cityBlockIds.filter((id) => config.blockTypes.wasteCleanUp.includes(id));

  if (!wasteCleanUpBlocks.length) {
    // users can cleanup the litter pan (mini game) to reduce pollution
    return 100;
  }
  // if user has waste clean up blocks, pollution is always 0
  return 0;
};

export default calculatePollution;
