import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useKittiesStore } from '../hooks/useKittiesStore';
import KittySelectorAll from './KittySelectorAll';

import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { getShittyUser } from '../firebase-client';
import { useAlgo } from '../hooks/useAlgo';
import useShittyUser from '../hooks/useShittyUser';
import FullScreenLoader from './FullScreenLoader';
import { getLocalImagePath } from '../utils/getLocalImagePath';

const ProfileAvatar = ({ width = 150, address, allowSelection }) => {
  const { myKitties, myKittiesLoading } = useKittiesStore();
  const { updateProfilePic, loading } = useShittyUser();
  const router = useRouter();
  const { address: activeWallet } = useAlgo();
  const { address: addressFromUrl } = router.query;
  const isMyProfile = addressFromUrl ? addressFromUrl === activeWallet : true;

  const userQuery = useQuery({
    queryKey: ['user', address],
    enabled: !!address,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchInterval: 20000,
    queryFn: async () => {
      const user = await getShittyUser({ address: address });
      return user || null;
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const user = userQuery.data;

  let avatarKitty = user?.avatar ? user?.avatar : 'placeholder';
  if (userQuery.isLoading) {
    avatarKitty = 'placeholder';
  }

  const imagePath = getLocalImagePath(avatarKitty);

  const onKittySelect = async (index, kitty) => {
    if (kitty.id === user?.avatar?.id) return;
    updateProfilePic(kitty);
  };

  if ((!avatarKitty || avatarKitty === 'placeholder') && !isMyProfile)
    return (
      <div style={{ width, height: width }} className='rounded-md bg-gray-700 relative items-center justify-center flex text-gray-300'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' fill='currentColor' style={{ width: width * 0.7 }}>
          <path d='M576 160c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-32.5 12.1-62.1 32-84.7V160c0 53 43 96 96 96s96-43 96-96V75.3c19.9 22.6 32 52.2 32 84.7zM416 96L374.9 54.9C395.6 40.5 420.8 32 448 32s52.4 8.5 73.1 22.9L480 96H416zm64 220.8C553 302 608 237.4 608 160C608 71.6 536.4 0 448 0S288 71.6 288 160c0 11.7 1.3 23.1 3.6 34.1c-54.9 8.1-102.2 39.5-131.6 83.8V192c0-53-43-96-96-96H32v64H64c17.7 0 32 14.3 32 32V480v32h32H336h32V448H336 280L416 339.2V480v32h64V480 316.8zM400 160a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zm80-16a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM241.3 46.7L224 0 206.7 46.7 160 64l46.7 17.3L224 128l17.3-46.7L288 64 241.3 46.7z' />
        </svg>
      </div>
    );

  return (
    <>
      {allowSelection && isMyProfile ? (
        <div className='relative'>
          <FullScreenLoader loading={loading} label='Updating profile avatar...' />
          <KittySelectorAll
            width={width}
            kitty={user?.avatar}
            kitties={myKitties}
            onSelect={onKittySelect}
            disabled={userQuery.isLoading || myKittiesLoading}
          />
        </div>
      ) : avatarKitty && avatarKitty !== 'placeholder' ? (
        <div
          id='profile-avatar'
          style={{ width, height: width }}
          className='rounded-md bg-gray-800 relative overflow-hidden'
          data-tooltip-content={`Image of ${avatarKitty?.name}`}
        >
          <LazyLoadImage
            src={imagePath}
            alt='profile avatar'
            width={1000}
            height={1000}
            className='absolute inset-0 object-cover'
          />
          <ReactTooltip anchorId='profile-avatar' />
        </div>
      ) : (
        <div className='rounded-md bg-gray-700 relative items-center justify-center flex text-gray-300' style={{ width, height: width }}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' fill='currentColor' style={{ width: width * 0.7 }}>
            <path d='M576 160c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-32.5 12.1-62.1 32-84.7V160c0 53 43 96 96 96s96-43 96-96V75.3c19.9 22.6 32 52.2 32 84.7zM416 96L374.9 54.9C395.6 40.5 420.8 32 448 32s52.4 8.5 73.1 22.9L480 96H416zm64 220.8C553 302 608 237.4 608 160C608 71.6 536.4 0 448 0S288 71.6 288 160c0 11.7 1.3 23.1 3.6 34.1c-54.9 8.1-102.2 39.5-131.6 83.8V192c0-53-43-96-96-96H32v64H64c17.7 0 32 14.3 32 32V480v32h32H336h32V448H336 280L416 339.2V480v32h64V480 316.8zM400 160a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zm80-16a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM241.3 46.7L224 0 206.7 46.7 160 64l46.7 17.3L224 128l17.3-46.7L288 64 241.3 46.7z' />
          </svg>
        </div>
      )}
    </>
  );
};

export default ProfileAvatar;
