import { flatten, uniq } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { create } from 'zustand';
import { useAlgo } from './useAlgo';
import { useAllKitties } from './useAllKitties';
import { useUserAssetsQuery } from './useUserAssetsStore';

export const legendaryKitties = [
  1661936756, 1661937207, 1661937460, 1661939156, 1661939241, 1661939377, 1661940297, 1661941386, 1661942679, 1661947194, 1661947770, 1661949117,
  1661949474, 1661951060, 1661951539, 1661951910, 1661951936, 1661957336, 1661963776, 1661968110, 1661968410, 1661971196, 1661973004, 1661974278,
  1661974727, 1661976067, 1661978596, 1661986709, 1661996104, 1661999788, 1662007488, 1662016074, 1662016339, 1662017724, 1662024948, 1662025186,
  1662029034, 1662033257, 1662037649, 1662037671, 1662040793, 1662046048, 1662046996, 1662047482, 1662047904, 1662047920, 1662059942, 1662060989,
  1662061053, 1662063859,
];

export const useKittiesStore = create((set) => ({
  myKitties: [],
  myKittiesLoading: false,
  myLegendaryKitties: [],
  kitties: [],
  defaultKitties: [],
  myAccessories: [],
  rewards: [],
  filters: [],
  sortBy: 'mint_date',
  query: '',
  currentGen: 1,

  setCurrentGen: (gen) => set({ currentGen: gen }),
  setMyKitties: (kitties) => set({ myKitties: kitties }),
  setMyLegendaryKitties: (kitties) => set({ myLegendaryKitties: kitties }),
  setMyKittiesLoading: (loading) => set({ myKittiesLoading: loading }),
  setDefaultKitties: (kitties) => set({ defaultKitties: kitties }),
  setKitties: (kitties) => set({ kitties }),
  setSortBy: (sortBy) => set({ sortBy }),
  setQuery: (query) => set({ query }),
  setFilters: (filters) => set({ filters }),
  setFilterActive: (sectionId, filter, active) => {
    set((state) => {
      return {
        filters: state.filters.map((section) => {
          if (section.id === sectionId) {
            section.options = section.options.map((option) => {
              if (option.value === filter) {
                option.active = active;
              }
              return option;
            });
          }

          return section;
        }),
      };
    });
  },
}));

export const useKittiesResolver = () => {
  const { allKitties } = useAllKitties();
  const router = useRouter();
  const addressFromUrl = router.query.address;
  const { address } = useAlgo();
  const userAssetsQuery = useUserAssetsQuery({ address: addressFromUrl || address });
  const userAssets = userAssetsQuery.data || [];

  const {
    defaultKitties,
    currentGen,
    filters,
    setDefaultKitties,
    setMyKitties,
    setMyKittiesLoading,
    setMyLegendaryKitties,
    setKitties,
    setFilters,
    sortBy,
    query,
  } = useKittiesStore();

  useEffect(() => {
    const myKitties = allKitties.filter((k) => {
      const kitty = userAssets.find((asset) => k.id === asset['asset-id']);
      if (kitty?.amount === 0) return false;
      return !!kitty;
    });
    setMyKitties(myKitties);
    setMyLegendaryKitties(myKitties.filter((k) => legendaryKitties.includes(k.id)));
  }, [allKitties.length, userAssets.length]);

  useEffect(() => {
    setMyKittiesLoading(userAssetsQuery.isLoading);
  }, [userAssetsQuery.isLoading]);

  useEffect(() => {
    let filterFnc = null;
    if (currentGen === 1) {
      filterFnc = (k) => k.name?.includes('Shitty');
    } else if (currentGen === 2) {
      filterFnc = (k) => k.name?.includes('SKSL');
    } else if (currentGen === 3) {
      filterFnc = (k) => k.name?.includes('SKTL');
    } else if (currentGen === 4) {
      filterFnc = (k) => k.name?.includes('SK4L');
    }
    const _kitties = allKitties.filter(filterFnc);
    setDefaultKitties(_kitties);
  }, [currentGen, allKitties.length]);

  useEffect(() => {
    _setInitialFilters();
  }, [defaultKitties?.length]);

  useEffect(() => {
    _filterKitties();
  }, [filters]);

  useEffect(() => {
    const sortedKitties = [...defaultKitties].sort((a, b) => {
      if (sortBy === 'rarity_score') {
        return a.rank - b.rank;
      }
      if (sortBy === 'likes') {
        return (b.bio?.likes?.length || 0) - (a.bio?.likes?.length || 0);
      }
      if (sortBy === 'level') {
        return (b.bio?.level || 1) - (a.bio?.level || 1);
      }
    });

    setKitties(sortedKitties);
  }, [sortBy]);

  const _getFilterOptions = function (trait_type) {
    const options = uniq(
      flatten(
        [...defaultKitties].map((kitty) => {
          return kitty?.attributes?.filter((attribute) => {
            return attribute.trait_type === trait_type;
          });
        })
      ).map((background) => background?.value)
    ).sort();

    return options.map((option) => ({
      value: option,
      label: option,
      trait_type,
      active: false,
    }));
  };

  const _setInitialFilters = function () {
    const backgrounds = _getFilterOptions('Background');
    const furs = _getFilterOptions('Fur');
    const accessories = _getFilterOptions('Accessory');
    const heads = _getFilterOptions('Head');
    const clothing = _getFilterOptions('Clothing');
    const clothes = _getFilterOptions('Clothes');
    const eyes = _getFilterOptions('Eyes');
    const mouthes = _getFilterOptions('Mouth');
    const whiskers = _getFilterOptions('Whiskers');
    const special = _getFilterOptions('Special');

    let filters = [
      {
        id: 'level',
        name: 'Level',
        options: [
          {
            value: 'Level 1',
            label: 'Level 1',
            active: false,
          },
          {
            value: 'Level 2',
            label: 'Level 2',
            active: false,
          },
          {
            value: 'Level 3',
            label: 'Level 3',
            active: false,
          },
          {
            value: 'Level 4',
            label: 'Level 4',
            active: false,
          },
          {
            value: 'Level 5',
            label: 'Level 5',
            active: false,
          },
          {
            value: 'Level 6',
            label: 'Level 6',
            active: false,
          },
          {
            value: 'Level 7',
            label: 'Level 7',
            active: false,
          },
          {
            value: 'Level 8',
            label: 'Level 8',
            active: false,
          },
          {
            value: 'Level 9',
            label: 'Level 9',
            active: false,
          },
          {
            value: 'Level 10',
            label: 'Level 10',
            active: false,
          },
        ],
      },
      {
        id: 'background',
        name: 'Background',
        options: backgrounds,
      },
      {
        id: 'furs',
        name: 'Furs',
        options: furs,
      },
      {
        id: 'clothing',
        name: 'Clothing',
        options: currentGen === 1 || currentGen === 3 ? clothing : clothes,
      },
      {
        id: 'heads',
        name: 'Heads',
        options: heads,
      },
      {
        id: 'accessory',
        name: 'Accessory',
        options: accessories,
      },
    ];

    if (currentGen === 2) {
      filters = [
        ...filters,
        {
          id: 'eyes',
          name: 'Eyes',
          options: eyes,
        },
        {
          id: 'mouthes',
          name: 'Mouthes',
          options: mouthes,
        },
      ];
    }

    if (currentGen === 3) {
      filters = [
        ...filters,
        {
          id: 'eyes',
          name: 'Eyes',
          options: eyes,
        },
        {
          id: 'mouthes',
          name: 'Mouthes',
          options: mouthes,
        },
        {
          id: 'whiskers',
          name: 'Whiskers',
          options: whiskers,
        },
      ];
    }

    if (currentGen === 4) {
      filters = [
        ...filters,
        {
          id: 'eyes',
          name: 'Eyes',
          options: eyes,
        },
        {
          id: 'mouthes',
          name: 'Mouthes',
          options: mouthes,
        },
        {
          id: 'whiskers',
          name: 'Whiskers',
          options: whiskers,
        },
        {
          id: 'special',
          name: 'Special',
          options: special,
        },
      ];
    }

    setFilters(filters);
  };

  const _filterKitties = function () {
    // what are the active filters
    const activeFilters = flatten(
      filters
        .filter((filter) => {
          return filter.options.some((option) => option.active);
        })
        .map((filter) => filter.options.filter((option) => option.active))
    );

    // reset the kitties
    if (activeFilters.length === 0) {
      setKitties(defaultKitties);
      return;
    }

    const filteredKitties = defaultKitties.filter((kitty) => {
      let valid = false;

      activeFilters.forEach((filter) => {
        if (filter.value.includes('Level')) {
          if (filter.value === 'Level 1') {
            valid = kitty.bio.level === 1;
          }
          if (filter.value === 'Level 2') {
            valid = kitty.bio.level === 2;
          }
          if (filter.value === 'Level 3') {
            valid = kitty.bio.level === 3;
          }
          if (filter.value === 'Level 4') {
            valid = kitty.bio.level === 4;
          }
          if (filter.value === 'Level 5') {
            valid = kitty.bio.level === 5;
          }
          if (filter.value === 'Level 6') {
            valid = kitty.bio.level === 6;
          }
          if (filter.value === 'Level 7') {
            valid = kitty.bio.level === 7;
          }
          if (filter.value === 'Level 8') {
            valid = kitty.bio.level === 8;
          }
          if (filter.value === 'Level 9') {
            valid = kitty.bio.level === 9;
          }
          if (filter.value === 'Level 10') {
            valid = kitty.bio.level === 10;
          }
        } else {
          const trait = kitty.attributes.find((attribute) => {
            return attribute.trait_type === filter.trait_type && attribute.value === filter.value;
          });

          if (trait) {
            valid = true;
          }
        }
      });

      return valid;
    });

    setKitties(filteredKitties);
  };
};
