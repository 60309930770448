import { useQueryClient } from '@tanstack/react-query';
import { useWallet } from '@txnlab/use-wallet';
import algosdk from 'algosdk';
import axios from 'axios';
import get from 'lodash/get';
import React, { createContext, useContext } from 'react';
import config from '../config';
import { algodClient } from '../utils/algo';
import finalizeBuild from '../utils/finalizeBuild';
import { getAssets } from '../utils/getAssets';
import { calculateParticipants, MAXIMUM_WINNERS } from '../utils/vending-machine';

const AlgoContext = createContext({});
export const useAlgo = () => useContext(AlgoContext);

export const AlgoProvider = ({ children }) => {
  const { activeAccount, signTransactions } = useWallet();
  const address = get(activeAccount, 'address', null);
  const queryClient = useQueryClient();

  const getAssetsFromAccessoryWallet = async (address) => {
    const response = await axios.get(`/api/assets?address=7SNDPEPR7APYXCARY2YD7ZM4XKLSOXU3ESDLCHXFQ66HLUFCBOAVIX5T3U`);
    return response.data;
  };

  const optIn = async function (assetID) {
    const params = await algodClient.getTransactionParams().do();

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: assetID,
        amount: 0,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
      sentTransactions.push(sentTransaction);
    }

    return sentTransactions[0];
  };

  const optInToAll = async function (assets) {
    if (assets.length === 0) {
      throw new Error('No assets to opt-in to');
    }

    // each asset should be a number
    if (assets.some((it) => typeof it !== 'number')) {
      throw new Error('All assets should be numbers');
    }

    // all should be unique
    if (new Set(assets).size !== assets.length) {
      // get the duplicates
      const duplicates = assets.filter((it, index) => assets.indexOf(it) !== index);
      throw new Error('All assets should be unique. Duplicates: ' + duplicates.join(', '));
    }

    const params = await algodClient.getTransactionParams().do();

    const transactions = assets.map((asset) =>
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: asset,
        amount: 0,
      })
    );

    // group
    algosdk.assignGroupID(transactions);

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    const response = await algodClient.sendRawTransaction(signedTxn).do();
    await algosdk.waitForConfirmation(algodClient, response.txId, 10);
    return response;
  };

  const sendAllAssets = async function (assets, to) {
    if (assets.length === 0) {
      throw new Error('No assets to opt-in to');
    }

    // each asset should be a number
    if (assets.some((it) => typeof it !== 'number')) {
      throw new Error('All assets should be numbers');
    }

    // all should be unique
    if (new Set(assets).size !== assets.length) {
      throw new Error('All assets should be unique');
    }

    if (!to) {
      throw new Error('No address to send to');
    }

    const params = await algodClient.getTransactionParams().do();

    const transactions = assets.map((asset) =>
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to,
        assetIndex: asset,
        amount: 1,
      })
    );

    // group
    algosdk.assignGroupID(transactions);

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    const response = await algodClient.sendRawTransaction(signedTxn).do();
    await algosdk.waitForConfirmation(algodClient, response.txId, 10);
    return response;
  };

  const exchangeMintPass = async function (count) {
    const params = await algodClient.getTransactionParams().do();

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: process.env.NEXT_PUBLIC_UTILITY_WALLET,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_SHITTIER_MINT_PASS_ID),
        amount: parseInt(count),
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sentTransactions.push(sentTransaction);
    }

    await axios.post(
      '/api/mint-pass-exchange',
      {
        address,
        txId: sentTransactions[0].txId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${process.env.NEXT_PUBLIC_API_KEY}`,
        },
      }
    );
  };

  const getExchanges = async () => {
    const { data } = await axios.get('/api/mint-pass-exchanges', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${process.env.NEXT_PUBLIC_API_KEY}`,
      },
    });
    return data;
  };

  const updateBio = async ({ kitty, name, quote }) => {
    const params = await algodClient.getTransactionParams().do();

    const enc = new TextEncoder();
    const note = enc.encode(`{"name": "${name.trim()}", "quote": "${quote.trim()}"}`);

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: kitty.id,
        amount: 0,
        note,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
      sentTransactions.push(sentTransaction);
    }

    const txnId = get(sentTransactions, '[0].txId');

    await axios({
      method: 'post',
      url: '/api/bio/update',
      data: {
        txnId,
        kitty,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });
  };

  const updateShittyUser = async (data) => {
    const params = await algodClient.getTransactionParams().do();

    const enc = new TextEncoder();
    const note = enc.encode(
      JSON.stringify({
        ...data,
        address,
      })
    );

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: 0,
        note,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
      sentTransactions.push(sentTransaction);
    }

    const txnId = get(sentTransactions, '[0].txId');

    await axios({
      method: 'post',
      url: '/api/user/update',
      data: {
        txnId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });
  };

  const withdrawFromVault = async () => {
    const params = await algodClient.getTransactionParams().do();

    const enc = new TextEncoder();
    const note = enc.encode(
      JSON.stringify({
        address,
      })
    );

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: 0,
        note,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
      sentTransactions.push(sentTransaction);
    }

    const txnId = get(sentTransactions, '[0].txId');

    await axios({
      method: 'post',
      url: '/api/user/vault',
      data: {
        txnId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });

    queryClient.invalidateQueries(['balance', address]);
  };

  const sendNFT = async function (payload) {
    await axios.post(
      `/api/send`,
      {
        payload,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${process.env.NEXT_PUBLIC_API_KEY}`,
        },
      }
    );
  };

  const optInForClaimReward = async function (rewardId) {
    if (!rewardId) {
      rewardId = parseInt(process.env.NEXT_PUBLIC_TREASURE_ID);
    }
    const params = await algodClient.getTransactionParams().do();
    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: 0,
      }),
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: rewardId,
        amount: 0,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sentTransactions.push(sentTransaction);
    }
    return sentTransactions;
  };

  const optInToAssetsForAdventureStart = async function (adventure) {
    const collab = get(adventure, 'requirements.collab');
    const treasureBox = get(collab, 'reward.id', parseInt(process.env.NEXT_PUBLIC_TREASURE_ID));

    const params = await algodClient.getTransactionParams().do();
    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: 0,
      }),
      // algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      //   suggestedParams: params,
      //   from: address,
      //   to: address,
      //   assetIndex: treasureBox,
      //   amount: 0,
      // }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sentTransactions.push(sentTransaction);
    }
    return sentTransactions;
  };

  const optInForOpenTreasure = async function (choiceOfBox, amountToOpen) {
    const params = await algodClient.getTransactionParams().do();
    const vendingMachineAssetsToPurchase = await getRandomVendingMachineAssetsToPurchase();

    const enc = new TextEncoder();
    const noteContents = { forOpenTreasure: true, address, choiceOfBox, amountToOpen, authorization: process.env.NEXT_PUBLIC_API_KEY };
    const secretNote = JSON.stringify(noteContents);
    const secretNoteEncoded = Buffer.from(secretNote).toString('base64');
    const note = enc.encode(secretNoteEncoded);

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: choiceOfBox.contents.token,
        amount: 0,
        note,
      }),
    ];
    if (choiceOfBox.contents.token !== parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID)) {
      transactions.push(
        algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: params,
          from: address,
          to: address,
          assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
          amount: 0,
          note,
        })
      );
    }
    const transactionsBeforeNfts = transactions.length;

    transactions.push(
      ...vendingMachineAssetsToPurchase.map((asset) =>
        algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: address,
          to: address,
          assetIndex: asset,
          amount: 0,
          note,
        })
      )
    );

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxns = await signTransactions(encodedTransactions);

    // get first few and then a random one
    const firstFew = signedTxns.slice(0, transactionsBeforeNfts);
    const theRest = signedTxns.slice(transactionsBeforeNfts);
    const randomSignedTxn = theRest[Math.floor(Math.random() * theRest.length)];
    const toSend = [...firstFew, randomSignedTxn];

    let sentTransactions = [];
    for (const txn of toSend) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sentTransactions.push(sentTransaction);
    }

    const txnId = get(sentTransactions, `[${transactionsBeforeNfts}].txId`);
    return txnId;
  };

  const drinkEnergyDrink = async ({ kitty }) => {
    const params = await algodClient.getTransactionParams().do();

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: kitty.id || kitty.index,
        amount: 0,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
      sentTransactions.push(sentTransaction);
    }

    const txnId = get(sentTransactions, '[0].txId');

    await axios({
      method: 'post',
      url: '/api/adventures/drink-energy-drink',
      data: {
        txnId,
        kitty,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });
  };

  const sendTokensToTokenWalletAndOptIn = async function (tokenId, assetId, amount) {
    const params = await algodClient.getTransactionParams().do();
    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: process.env.NEXT_PUBLIC_TREAT_TOKEN_WALLET,
        assetIndex: tokenId,
        amount: amount,
      }),
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: parseInt(assetId),
        amount: 0,
      }),
    ];

    algosdk.assignGroupID(transactions);

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxnObjects = await signTransactions(encodedTransactions);
    const signedTxns = signedTxnObjects.map((txn) => Buffer.from(txn).toString('base64'));
    return signedTxns;
  };

  const getRandomVendingMachineAssetsToPurchase = async function (numberOfWinningTxns) {
    const vendingMachineAssetsAll = await getAssets(process.env.NEXT_PUBLIC_VENDING_MACHINE_WALLET);
    const vendingMachineAssets = vendingMachineAssetsAll.filter((asset) => asset.amount > 0);
    // if the asset amount is > 1 then we need to add that many items to the array
    const vendingMachineAssetsArray = vendingMachineAssets.reduce((acc, asset) => {
      for (let i = 0; i < asset.amount; i++) {
        acc.push(asset);
      }
      return acc;
    }, []);

    const randomizedVendingMachineAssets = vendingMachineAssetsArray.sort(() => Math.random() - 0.5);
    const vendingMachineAssetsToPurchase = randomizedVendingMachineAssets.slice(0, MAXIMUM_WINNERS);
    return vendingMachineAssetsToPurchase.map((asset) => asset['asset-id']);
  };

  const purchaseFromVendingMachine = async ({ cost, vmTokenBalance }) => {
    const vendingMachineAssetsToPurchase = await getRandomVendingMachineAssetsToPurchase();
    const { losers } = calculateParticipants(vmTokenBalance);

    const params = await algodClient.getTransactionParams().do();

    const enc = new TextEncoder();
    const secretNoteObj = {
      vendingMachinePurchase: 'true',
      address,
      authorization: process.env.NEXT_PUBLIC_API_KEY,
      cost,
      vmTokenBalance,
    };
    const secretNote = JSON.stringify(secretNoteObj);
    const secretNoteEncoded = Buffer.from(secretNote).toString('base64');
    const note = enc.encode(secretNoteEncoded);

    const transactions = vendingMachineAssetsToPurchase.map((asset) =>
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: asset,
        amount: 0,
        note,
      })
    );

    const losingTransactions = Array.from({ length: losers }, () =>
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_SVMT_ID),
        amount: 0,
        note,
      })
    );

    const encodedTransactions = [...losingTransactions, ...transactions].map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxns = await signTransactions(encodedTransactions);

    const randomSignedTxn = signedTxns[Math.floor(Math.random() * signedTxns.length)];
    const sentTransaction = await algodClient.sendRawTransaction(randomSignedTxn).do();

    // wait for
    const txnId = get(sentTransaction, 'txId');

    return await axios({
      method: 'post',
      url: '/api/vending-machine/buy',
      data: {
        txnId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });
  };

  const sendBetForFlip = async ({ betAmount, choiceOfToken, choiceToWin }) => {
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();
    const secretNote = enc.encode(`{"betAmount": "${betAmount}", "choiceOfToken": "${choiceOfToken}", "choiceToWin": "${choiceToWin}"}`);
    const secretNoteEncoded = Buffer.from(secretNote).toString('base64');
    const note = enc.encode(secretNoteEncoded);
    const divisible = config.flipTokenChoices.find((choice) => choice.id === choiceOfToken)?.divisible;
    const amount = Math.round(betAmount + betAmount * 0.03);

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: process.env.NEXT_PUBLIC_FLIP_WALLET,
        assetIndex: choiceOfToken,
        amount: divisible ? amount * divisible : amount,
        note,
      }),
    ];
    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sentTransactions.push(sentTransaction);
    }

    return sentTransactions[0].txId;
  };

  const sendTransferForCostume = async () => {
    const params = await algodClient.getTransactionParams().do();

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: process.env.NEXT_PUBLIC_TREAT_TOKEN_WALLET,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: parseInt(process.env.NEXT_PUBLIC_COSTUME_COST),
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sentTransactions = [];

    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sentTransactions.push(sentTransaction);
    }

    return sentTransactions[0].txId;
  };

  const swapPasses = async (amount) => {
    const { data } = await axios.post(
      '/api/mint-pass-swap',
      {
        address,
        amount,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${process.env.NEXT_PUBLIC_API_KEY}`,
        },
      }
    );

    const signedClientTxns = await signTransactions([data.sendOldEnc, data.optInEnc, data.sendNewSignedTxn]);

    // make sure each one is a Uint8Array if they are not already
    const toSend = signedClientTxns.map((txn) => {
      if (txn instanceof Uint8Array) {
        return txn;
      } else {
        return Uint8Array.from(txn);
      }
    });

    const sentTransaction = await algodClient.sendRawTransaction(toSend).do();
    try {
      await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
    } catch (error) {
      console.error(error);
    }

    return sentTransaction.txId;
  };

  const optInForMoveIn = async function (cityIndex, selectedKitties) {
    const input = {
      selectedKitties,
      cityIndex,
      address,
    };
    const note = formatSecretNote(input);
    return await optInFor(note);
  };

  const optInForMoveOut = async function (cityIndex, kittyId) {
    const input = {
      kittyId,
      cityIndex,
      address,
    };
    const note = formatSecretNote(input);
    return await optInFor(note);
  };

  const formatSecretNote = (input) => {
    const enc = new TextEncoder();
    const secretNoteStr = JSON.stringify(input);
    const secretNoteEncoded = Buffer.from(secretNoteStr).toString('base64');
    const note = enc.encode(secretNoteEncoded);
    return note;
  };

  const sendBlocksInitiate = async function (cityIndex, cityCid, originalBlocks, updatedBlocks, originalUpgrade, currentUpgrade) {
    if (!address) {
      throw new Error('No address provided');
    }

    const addedBlocks = updatedBlocks
      .filter((block) => {
        const originalBlock = originalBlocks.find((b) => b.index === block.index);
        return originalBlock?.block?.index !== block?.block?.index;
      })
      .filter((block) => !!block);
    const removedBlocks = originalBlocks
      .filter((block) => {
        const updatedBlock = updatedBlocks.find((b) => b.index === block.index);
        return updatedBlock?.block?.index !== block?.block?.index;
      })
      .filter((block) => !!block);

    const formattedUpdatedBlocks = {};
    Object.values(updatedBlocks).forEach((block, index) => {
      formattedUpdatedBlocks[index + 1] = block?.block?.index;
    });
    const enc = new TextEncoder();
    const secretNoteObject = {
      address,
      authorization: process.env.NEXT_PUBLIC_API_KEY,
      blocksInCity: formattedUpdatedBlocks,
      originalBlocks: originalBlocks.map((block) => block?.block?.index),
      addedBlocks: addedBlocks.map((block) => block?.block?.index),
      removedBlocks: removedBlocks.map((block) => block?.block?.index),
      cityIndex,
      cityCid,
      originalUpgrade: originalUpgrade?.name,
      currentUpgrade: currentUpgrade?.name,
    };
    const secretNoteStr = JSON.stringify(secretNoteObject);
    const secretNoteEncoded = Buffer.from(secretNoteStr).toString('base64');
    const note = enc.encode(secretNoteEncoded);
    const params = await algodClient.getTransactionParams().do();

    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: 0,
        note,
      }),
    ];

    algosdk.assignGroupID(transactions);
    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxnObjects = await signTransactions(encodedTransactions);
    const signedTxns = signedTxnObjects.map((txn) => Buffer.from(txn).toString('base64'));
    return signedTxns;
  };

  const signAndSendBlocksConfirm = async function (txns) {
    const { u, a } = txns;
    const decodedU = u.map((txn) => Uint8Array.from(Buffer.from(txn, 'base64')));
    const decodedA = a.map((txn) => Uint8Array.from(Buffer.from(txn, 'base64')));

    const signedTransactions = await signTransactions([...decodedU, ...decodedA]);

    const toSend = signedTransactions.map((txn) => {
      if (txn instanceof Uint8Array) {
        return txn;
      } else {
        return Uint8Array.from(txn);
      }
    });

    const sentTransaction = await algodClient.sendRawTransaction(toSend).do();

    try {
      await finalizeBuild(sentTransaction.txId);
    } catch (error) {
      console.error(error);
    }
  };

  const optInFor = async function (note) {
    const params = await algodClient.getTransactionParams().do();
    const transactions = [
      algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: params,
        from: address,
        to: address,
        assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
        amount: 0,
        note,
      }),
    ];

    const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
    const signedTxn = await signTransactions(encodedTransactions);
    let sendTransactions = [];
    for (const txn of signedTxn) {
      const sentTransaction = await algodClient.sendRawTransaction(txn).do();
      sendTransactions.push(sentTransaction);
    }
    return sendTransactions[0].txId;
  };

  const optInForClaimTreats = async function (cityIndex) {
    const input = {
      cityIndex,
      address,
    };
    const note = formatSecretNote(input);
    return await optInFor(note);
  };

  const optInForUpdateCityDetails = async function (details) {
    const input = {
      ...details,
      address,
    };
    const note = formatSecretNote(input);
    return await optInFor(note);
  };

  const optInForBlockStaking = async function (details) {
    const input = {
      ...details,
      address,
    };
    const note = formatSecretNote(input);
    return await optInFor(note);
  };

  return (
    <AlgoContext.Provider
      value={{
        getAssets,
        address,
        optIn,
        optInToAll,
        sendAllAssets,
        sendNFT,
        getAssetsFromAccessoryWallet,
        updateBio,
        updateShittyUser,
        withdrawFromVault,
        sendTokensToTokenWalletAndOptIn,
        optInForClaimReward,
        optInToAssetsForAdventureStart,
        purchaseFromVendingMachine,
        exchangeMintPass,
        getExchanges,
        sendBetForFlip,
        optInForOpenTreasure,
        sendTransferForCostume,
        swapPasses,
        drinkEnergyDrink,
        optInForClaimTreats,
        optInForUpdateCityDetails,
        optInForBlockStaking,
        sendBlocksInitiate,
        signAndSendBlocksConfirm,
        optInForMoveIn,
        optInForMoveOut,
      }}
    >
      {children}
    </AlgoContext.Provider>
  );
};
