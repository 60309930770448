import { forwardRef, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, InformationCircleIcon, BookOpenIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import config from '../config';
import Image from 'next/image';

const callsToAction = [
  { name: 'View Docs', href: config.docs, icon: BookOpenIcon },
  { name: 'New to Algorand?', href: 'https://www.howtoalgonft.com', icon: InformationCircleIcon },
];

const MyLink = forwardRef((props, ref) => {
  let { href, children, ...rest } = props;
  return (
    <Link href={href}>
      <a ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  );
});

export default function FlyoutMenu({ title, links }) {
  return (
    <Popover className='z-0'>
      {({ open }) => (
        <>
          <div className='z-10'>
            <Popover.Button
              className={`font-bold transition-colors duration-200 outline-none hover:text-primary-500 inline-flex items-center text-xl`}
            >
              {title}
              <ChevronDownIcon className='w-5 h-5 ml-1 -mr-1' aria-hidden='true' />
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 -translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 -translate-y-1'
          >
            <Popover.Panel className='absolute inset-x-0 top-[76px] z-10 transform shadow-lg'>
              <div className='bg-gray-800'>
                <div className='mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-12 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16'>
                  {links.map((item) => (
                    <Popover.Button
                      as={MyLink}
                      key={item.name}
                      href={item.href}
                      className='overflow-hidden relative -m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-900 group'
                    >
                      <div className='absolute inset-0 z-10 group-hover:opacity-50 opacity-90 transition duration-300'>
                        <div className='absolute inset-0 z-20 bg-gray-900 bg-opacity-90'></div>
                        <Image layout='fill' src={item.image} alt={item.name} className='absolute inset-0 z-10 object-cover w-full h-full' />
                      </div>

                      <div className='flex md:h-full lg:flex-col relative z-20'>
                        <div className='ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4'>
                          <div>
                            <p className='text-lg font-medium text-gray-100'>{item.title}</p>
                            <p className='mt-1 text-base text-gray-400'>{item.description}</p>
                          </div>
                          <p className='mt-2 text-sm flex items-center gap-2 font-medium text-primary-500 lg:mt-4'>
                            <span>Check it out</span>
                            <span aria-hidden='true' className='block group-hover:translate-x-1 transition duration-200'>
                              &rarr;
                            </span>
                          </p>
                        </div>
                      </div>
                    </Popover.Button>
                  ))}
                </div>
              </div>
              <div className='bg-gray-900'>
                <div className='mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8'>
                  {callsToAction.map((item) => (
                    <div key={item.name} className='flow-root'>
                      <Popover.Button
                        as={MyLink}
                        href={item.href}
                        className='-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-50 transition duration-150 ease-in-out hover:bg-gray-800 outline-none'
                      >
                        <item.icon className='h-6 w-6 flex-shrink-0 text-gray-400' aria-hidden='true' />
                        <span className='ml-3'>{item.name}</span>
                      </Popover.Button>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
