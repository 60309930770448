import { useQuery } from '@tanstack/react-query';
import { getKittiesCache } from '../firebase-client';

const useAllKittiesQuery = () => {
  return useQuery({
    queryKey: ['allKitties'],
    queryFn: getKittiesCache,
    refetchInterval: 120000,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useAllKitties = () => {
  const allKittiesQuery = useAllKittiesQuery();
  return {
    allKitties: allKittiesQuery.data || [],
    isLoading: allKittiesQuery.isLoading,
    isError: allKittiesQuery.isError,
  };
};
