import calculateCityRating from './calculateCityRating';
import calculateMaxPopulation from './calculateMaxPopulation';
import calculateFoodSupply from './calculateFoodSupply';
import calculateEntertainment from './calculateEntertainment';
import calculatePollution from './calculatePollution';

export function formatCities(cities) {
  return cities.map(formatCity(cities));
}

export function formatCity(cities) {
  return (city) => {
    city.currentPopulation = city.citizens?.length || 0;
    city.rarityScore = calculateCityRating(city, cities);
    city.maxPopulation = calculateMaxPopulation({ city });
    city.foodSupply = calculateFoodSupply(city);
    city.entertainment = calculateEntertainment(city);
    city.pollution = calculatePollution(city);
    return city;
  };
}
