const raffleOwners = [
  {
    id: 'YufGxgx3fLd4Y63WXq8rrkSilsB3',
    collection: 'Shitty Kitties',
    link: 'https://www.shittykitties.art',
    color: '#84C341',
    token: 796490640,
    tokenAbbr: 'Treats',
  },
  {
    id: 'D6xecb7LtESwoSPUiy02WiiwgOk1',
    collection: 'Totally Average Cats',
    link: 'https://www.asalytic.app/collection/totally-average-cats',
    color: '#FFCE65',
    token: 911073983,
    tokenAbbr: 'TAT',
  },
  {
    id: '2DfssTzYMgMqRsHSY4kKmaOoCjw2',
    collection: 'Lundi',
    link: 'https://twitter.com/thelundis',
    color: '#17F0ED',
    token: 658399558,
    tokenAbbr: 'Poof',
  },
  {
    id: '3AYu1l3b6CSb1T0EJMLC36si5t03',
    collection: 'Parliament of AOWLs',
    link: 'https://www.asalytic.app/collection/algotheowl',
    color: '#FAE34C',
    token: 797382233,
    tokenAbbr: 'HOOT',
  },
  {
    id: 'Dtj340X3s8grjDogVvqLky1Qaxy2',
    collection: 'Meowlgo',
    link: 'https://www.asalytic.app/collection/meowlgo',
    color: '#574F93',
    token: 1101538185,
    tokenAbbr: 'RAT',
  },
  {
    id: 'LHGm6mUOUXOYAcjClF859oV9nSD3',
    collection: 'skuli frens',
    link: 'https://www.asalytic.app/collection/skuli-frens',
    color: '#574F93',
    token: 704036829,
    tokenAbbr: 'Snacks',
  },
  {
    id: 'QjMcVNrVM7VteCpPu5Edk27DXT02',
    collection: 'Sigh!',
    link: 'https://www.asalytic.app/collection/sigh',
    color: '#C9A7C7',
    token: 1091977098,
    tokenAbbr: 'sigh',
  },
  {
    id: 'e7zT2pDtogfNcpAIhp68LyN40Wr1',
    collection: 'JoeJoBabbit',
    link: 'https://www.asalytic.app/collection/joejobabbit',
    color: '#C429F5',
    token: 1086216637,
    tokenAbbr: 'BBUCKS',
  },
  {
    id: '7YWMz5DMOXRRpBRWwfiskuSPnwC2',
    collection: 'Loopies',
    link: 'https://www.asalytic.app/collection/loopies',
    color: '#B2384E',
    token: 1265255981,
    tokenAbbr: 'LOLLY',
  },
  {
    id: 'jOpFj32ERHPavLjQtWoYY7J2CW83',
    collection: 'Mean Rabbits',
    link: 'https://www.asalytic.app/collection/mean-rabbits',
    color: '#5E6A72',
    token: 1275731557,
    tokenAbbr: 'MEAN',
  },
  {
    id: 'vFPeYGNQtUT8aIvIdpukUCWctUp2',
    collection: 'MembersOnly',
    link: 'https://www.asalytic.app/collection/MembersOnly',
    color: '#5E6A72',
    token: 885835936,
    tokenAbbr: 'MemO',
  },
  {
    id: 'Ety3wNPWKHVTRZL44Q4VoBtV1R63',
    collection: 'ALGONOOTS',
    link: 'https://twitter.com/ALGONOOTS',
    color: '#FAE34C',
    token: 933539255,
    tokenAbbr: 'ANC',
  },
  {
    id: 'b7ddpquCZVT6HbsQWd8dpWuJsMy1',
    collection: 'Infinite Mice',
    link: 'https://x.com/InfiniteMices',
    color: '#B2384E',
    token: 1037581316,
    tokenAbbr: 'pizza',
  },
];

export default raffleOwners;
