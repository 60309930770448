import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAlgo } from './useAlgo';
import { get } from 'lodash';

const AdventuresContext = createContext({});
export const useAdventures = () => useContext(AdventuresContext);

export const AdventuresProvider = ({ children }) => {
  const { address, optInToAssetsForAdventureStart, optInForClaimReward } = useAlgo();
  const [adventuresLoading, setAdventuresLoading] = useState(false);
  const [adventures, setAdventures] = useState([]);

  const loadAdventures = async (shouldLoad = true) => {
    if (!address) return;

    try {
      if (shouldLoad) {
        setAdventuresLoading(true);
      }
      const { data } = await axios.get('/api/adventures');
      for (const adventure of data) {
        try {
          const { data } = await axios.get(`/api/adventures/entry?address=${address}&adventureId=${adventure.id}`);
          adventure.entry = data;
        } catch (error) {}
      }
      setAdventures(data);
    } catch (error) {
    } finally {
      setAdventuresLoading(false);
    }
  };

  const checkAdventureForEntries = async (adventureId) => {
    try {
      const { data } = await axios.get(`/api/adventures/has-entries?adventureId=${adventureId}`);
      return data;
    } catch (error) {
      throw new Error(get(error, 'response.data.error'));
    }
  };

  const startAdventure = async (adventure, party) => {
    if (!adventure) {
      throw new Error('Id is required');
    }

    if (party.length !== adventure.requirements.partySize) {
      throw new Error(`Your party isn't big enough to start the adventure.`);
    }

    const partyKitties = party.map((kitty) => kitty.id);
    const uniqueKitties = [...new Set(partyKitties)];
    if (partyKitties.length !== uniqueKitties.length) {
      throw new Error('You cannot have the same kitty in your party twice.');
    }

    try {
      const txns = await optInToAssetsForAdventureStart(adventure);
      await axios.post(
        '/api/adventures/start',
        {
          adventureId: adventure.id,
          party,
          address,
          txn: txns[0].txId,
        },
        {
          headers: {
            Authorization: process.env.NEXT_PUBLIC_API_KEY,
          },
        }
      );
      loadAdventures(false);
    } catch (error) {
      throw new Error(get(error, 'response.data.error'));
    }
  };

  const claimReward = async (entry, rewardId) => {
    try {
      const txns = await optInForClaimReward(rewardId);
      const { data } = await axios.post(
        '/api/adventures/claim',
        {
          adventureId: entry.adventureId,
          address: entry.address,
          txn: txns[0].txId,
        },
        {
          headers: {
            Authorization: process.env.NEXT_PUBLIC_API_KEY,
          },
        }
      );
      return data;
    } catch (error) {
      console.error(error);
      throw new Error(error.response.data.error);
    }
  };

  const resetAdventure = async (adventureId) => {
    try {
      const txns = await optInToAssetsForAdventureStart(true);
      await axios.post(
        '/api/adventures/reset',
        {
          adventureId,
          address,
          txn: txns[0].txId,
        },
        {
          headers: {
            Authorization: process.env.NEXT_PUBLIC_API_KEY,
          },
        }
      );
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  };

  useEffect(() => {
    if (!address && adventures.length > 0) return;
    loadAdventures();
  }, [address]);

  return (
    <AdventuresContext.Provider
      value={{
        loadAdventures,
        adventures,
        adventuresLoading,
        startAdventure,
        claimReward,
        resetAdventure,
        checkAdventureForEntries,
      }}
    >
      {children}
    </AdventuresContext.Provider>
  );
};
