import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { create } from 'zustand';
import { getAllCities } from '../firebase-client';

const useAllCitiesQuery = () => {
  return useQuery({
    queryKey: ['allCities'],
    queryFn: getAllCities,
    refetchInterval: 120000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useCitiesStore = create((set) => ({
  allCities: [],
  setAllCities: (allCities) => set({ allCities }),
  loading: true,
  setLoading: (loading) => set({ loading }),
}));

export const useCitiesResolver = () => {
  const allCitiesQuery = useAllCitiesQuery();
  const allCities = allCitiesQuery.data ?? [];

  const { setAllCities, setLoading } = useCitiesStore();

  useEffect(() => {
    if (allCities) {
      setAllCities(allCities);
    }
  }, [allCities?.length]);

  useEffect(() => {
    setLoading(allCitiesQuery.isLoading);
  }, [allCitiesQuery.isLoading]);
};
