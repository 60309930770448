import config from '../config';

const calculateEntertainment = (city) => {
  const cityBlockIds = Object.values(city.metadata.properties)
    .filter((prop) => typeof prop === 'string' && prop.includes(' - '))
    .map((prop) => parseInt(prop.split(' - ')[1]));
  const entertainmentBlocks = cityBlockIds.filter((id) => config.blockTypes.entertainment.includes(id));
  if (!entertainmentBlocks.length) {
    // users can complete quests/desires to get entertainment
    return 0;
  }

  return 100;
};

export default calculateEntertainment;
