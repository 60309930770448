import axios from 'axios';

export const getAssets = async (addr) => {
  if (!addr) {
    return [];
  }
  try {
    const {data} = await axios.get(`/api/assets?address=${addr}`);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
