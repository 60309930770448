export const ogCityImageUrl = '/builder/og-city-client.png';

const cityUpgrades = [
  {
    name: 'Basic',
    treatsCost: 7500,
    algoCost: 10,
    imageUrlClient: '/builder/basic-city-client.png',
    imageUrlServer: '/builder/basic-city-server.png',
  },
  {
    name: 'Island',
    treatsCost: 12000,
    algoCost: 20,
    imageUrlClient: '/builder/island-city-client.png',
    imageUrlServer: '/builder/island-city-server.png',
    requirements: {
      blocks: ['Beach', 'Lighthouse', 'Old Cave Cave', 'Water Park'],
      traits: ['Legendary', 'Muscle Suit', 'Board Shorts', 'Grass Skirt', 'Pool Float', 'Coconut Bra', 'Bikini', 'Pirate Clothes'],
    },
  },
  {
    name: 'Arctic',
    treatsCost: 12000,
    algoCost: 20,
    imageUrlClient: '/builder/arctic-city-client.png',
    imageUrlServer: '/builder/arctic-city-server.png',
    requirements: {
      blocks: ['Frozen Pond', 'Igloo', 'Ski Resort', 'Winter Resort'],
      traits: [
        'Legendary',
        'Scarf And Gloves',
        'Faux Fur Coat',
        'Puffy Winter Coat',
        'Skis',
        'Snow Hat',
        'Ice Crown',
        'Snowflake Headband',
        'Penguin',
        'Beanie',
      ],
    },
  },
  {
    name: 'Wasteland',
    treatsCost: 12000,
    algoCost: 20,
    imageUrlClient: '/builder/wasteland-city-client.png',
    imageUrlServer: '/builder/wasteland-city-server.png',
    requirements: {
      blocks: ['Desert', 'Nuclear Power Plant', 'Slime River', 'Rundown House', 'Asteroids'],
      traits: ['Legendary', 'Zombie', 'Atomic', 'Slime', 'Slime Bucket'],
    },
  },
  {
    name: 'Jungle',
    treatsCost: 12000,
    algoCost: 20,
    imageUrlClient: '/builder/jungle-city-client.png',
    imageUrlServer: '/builder/jungle-city-server.png',
    requirements: {
      blocks: ['Ropes Course', 'Old Cave Cave', 'Tree House'],
      traits: ['Legendary', 'Banana Hat', 'Banana Costume', 'Treasure Map', 'Wide Brim Hat', 'Banana'],
    },
  },
  {
    name: 'Underwater',
    treatsCost: 12000,
    algoCost: 20,
    imageUrlClient: '/builder/underwater-city-client.png',
    imageUrlServer: '/builder/underwater-city-server.png',
    requirements: {
      blocks: ['Aquarium', 'Water Park'],
      traits: ['Legendary', 'Fish Bowl', 'Water', 'Jellyfish', 'Octopus'],
    },
  },

  {
    name: 'Space Station',
    treatsCost: 33000,
    algoCost: 30,
    imageUrlClient: '/builder/space-city-client.png',
    imageUrlServer: '/builder/space-city-server.png',
    requirements: {
      blocks: ['Moon Landing', 'Moon Dome', 'Space Dimension', 'The Encounter'],
      traits: ['Legendary', 'Space Helmet', 'Space Suit'],
    },
  },
  {
    name: 'Biodome',
    treatsCost: 33000,
    algoCost: 30,
    imageUrlClient: '/builder/biodome-city-client.png',
    imageUrlServer: '/builder/biodome-city-server.png',
    requirements: {
      blocks: ['Moon Landing', 'Moon Dome', 'The Encounter'],
      traits: ['Legendary', 'Space Helmet', 'Space Suit'],
    },
  },
];

export default cityUpgrades;
