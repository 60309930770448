import config from '../config';

const calculateFoodSupply = (city) => {
  if (!city.metadata || !city.metadata.properties) return 0;

  const cityBlockIds = Object.values(city.metadata.properties)
    .filter((prop) => typeof prop === 'string' && prop.includes(' - '))
    .map((prop) => parseInt(prop.split(' - ')[1]));
  const foodBlocks = cityBlockIds.filter((id) => config.blockTypes.restaurants.includes(id));

  if (!foodBlocks.length) {
    // todo - last fed with shitty treats
    return 0;
  }

  return 100;
};

export default calculateFoodSupply;
