export const getLocalImagePath = (nft) => {
  const { gen, image } = nft;

  if (nft?.params?.name && nft.params.name.includes('Shitty City')) {
    return nft.image;
  }

  let path = '';

  if (gen === 1) {
    path = 'first-litter';
  } else if (gen === 2) {
    path = 'second-litter';
  } else if (gen === 3) {
    path = 'third-litter';
  } else if (gen === 4) {
    path = 'fourth-litter';
  } else if (gen === 'collab') {
    path = 'collab-litter';
  }

  return `https://shittykitties.art/${path}${image}.png`;
};
